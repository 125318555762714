import { AdminStoreDataListEnum } from '@/store/modules/admin/types/AdminStoreDataListEnum';
import store from '@/store/store';
import { ITextValue } from '@/types/common';

export const specializationTypes = [
  // Tenants and Occupiers
  { divider: true },
  { header: 'Tenants & Occupiers' },
  { divider: true },
  { text: 'Cannabis', value: 'cannabis' },
  { text: 'Corporate Solutions', value: 'corporate_solutions' },
  { text: 'Data Centers', value: 'data_centers' },
  { text: 'Food Services', value: 'food_services' },
  { text: 'Film TV & Production', value: 'film' },
  { text: 'Life Sciences and Labratories', value: 'life-sciences' },
  { text: 'Logistics and Supply Chain', value: 'logistics' },
  { text: 'Transportation', value: 'transportation' },
  // Landlords and Investors
  { divider: true },
  { header: 'Landlords & Investors' },
  { divider: true },
  { text: 'Capital Markets', value: 'capital_markets' },
  { text: 'Investment Sales', value: 'investment_sales' },
  { text: 'Debt & Equity Financing', value: 'debt_financing' },
  { text: 'Insolvency and Restructuring', value: 'involvency' },
  { text: 'Office and Agency Leasing', value: 'office_leasing' },
  // Construction Services
  { divider: true },
  { header: 'Construction Services' },
  { divider: true },
  { text: 'Ground-Up Development', value: 'ground_up_development' },
  { text: 'Project Management', value: 'project_management' },
  { text: 'Tenant Improvements', value: 'tenant_improvements' },
  // Integrated Services
  { divider: true },
  { header: 'Integrated Services' },
  { divider: true },
  { text: 'Facilities Management', value: 'facilities_management' },
  { text: 'Lease Accounting', value: 'lease_accounting' },
  { text: 'Lease Administration', value: 'lease_administration' },
  { text: 'Portfolio Management', value: 'portfolio_management' },
  { text: 'Property Management', value: 'property_management' },
  { text: 'Treasury Services', value: 'treasury_services' },
  // Administrative Staff
  { divider: true },
  { header: 'Administrative Staff' },
  { divider: true },
  { text: 'Marketing', value: 'marketing' },
  { text: 'Office Operations & Management', value: 'office_operations' },
  { text: 'Research', value: 'research' },
];

export function getSpecializationTypes () {
  return specializationTypes;
}

export const getSpecializationSelectItems = () => {
  const getListByCategory = (category: string) => store.getters['Admin/getListByCategory'](category);
  const propertyTypes = getListByCategory(AdminStoreDataListEnum.property_types).items;

  return [
    { divider: true },
    { header: 'Property Types' },
    { divider: true },
    ...propertyTypes.map((item) => ({ text: item, value: item, class: 'red' })),
    ...getSpecializationTypes(),
  ] as ITextValue<string>[];
};
