
export const getDefaultPipelineFormData = () => ({
  property_type: '',
  category: '',
  deal_name: '',
  size: 0,
  land_size: 0,
  source: null,
  consideration: '',
  commission: '',
  commission_per: '',
  commission_total: '',
  probability: '',
  close_date: null,
  stage: 1,
  brokers: [],
  agreement: {
    type: '',
    files: [],
    expiration_date: '',
  },
  is_close_date_estimated: true,
  businessLine: 'brokerage'
});

export const getSelectItems = () => ({
  transactionCategories: [
    'Tenant Representation',
    'Buyer Representation',
    'Landlord Representation',
    'Seller Representation',
    'Dual Agency',
  ],
  probability: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  sources: [
    { text: 'Cold Call', value: 'cold-call' },
    { text: 'Sign Call', value: 'sign-call' },
    { text: 'Inbound Broker Referral', value: 'inbound-broker-referral' },
    { text: 'Outbound Client Referral', value: 'outbound-client-referral' },
    { text: 'Inbound Web', value: 'inbound-web' },
    { text: 'Repeat Client', value: 'past-client' },
    { text: 'Direct Marketing', value: 'direct-marketing' }
  ],
  businessLines: [
    { text: 'Brokerage', value: 'brokerage' },
    { text: 'Integrated Services', value: 'property-management' },
  ]
});
