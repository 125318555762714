import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/store';
import MarketingRoutes from './routes/marketing-routes';
import ReportRoutes from './routes/report-routes';
import MarketingTemplatesRoutes from './routes/marketing-templates-routes';

import Dashboard from '@/views/Dashboard';
import Welcome from '@/components/Welcome';
import AccountLayout from '@/bundles/App/components/AccountLayout';
import {
  redirectHandler,
  requiresTokenGuard,
  requiresRedirectGuard,
  requiresAdminTokenGuard,
  requiresTokenAbsenceGuard,
  requiresSuperAdminRoleGuard,
  requiresFinancialAdminRoleGuard,
  requiresTokenOrTemporaryTokenGuard,
  requiresFinancialAdminOrAdminRoleGuard,
} from './guards';
import { RootActions } from '@/store/types/action-types';

// lazy routes
const ReportDetail = () => import('@/views/Reports/ReportDetail')
const CompaniesMergeWindow = () => import('@/components/companies/CompaniesMergeWindow')
const SavedSets = () => import('@/views/SavedSet/SavedSets')
const PipelinesNew = () => import('@/views/Pipeline/PipelinesNew')
const OccupiersNew = () => import('@/views/Occupier/OccupiersNew')
const RequirementsNew = () => import('@/views/Requirement/RequirementsNew')
const ProjectsNew = () => import('@/views/Project/ProjectsNew')
const SignUp = () => import('@/components/SignUp')
const ConfirmAccount = () => import('@/components/ConfirmAccount')
const Register = () => import('@/components/Register')
const CreateAccount = () => import('@/components/CreateAccount')
const AdministrationRootView = () => import('@/bundles/Administration/components/RootView/AdministrationRootView.vue');
const Administration = () => import('@/components/account/administration')
const CompaniesNew = () => import('@/views/Company/CompaniesNew')
const Properties = () => import('@/views/Property/Properties')

// Availabilities
const Availability = () => import('@/views/Availability/Availability')
const AvailabilityForm = () => import('@/components/availabilities/AvailabilityForm')
const AvailabilityEditForm = () => import('@/components/availabilities/AvailabilityEditForm')
const Availabilities = () => import('@/views/Availability/Availabilities')

const ProjectCard = () => import('@/views/Project/ProjectNew')
const Occupier = () => import('@/views/Occupier/OccupierNew')
const Requirement = () => import('@/views/Requirement/RequirementNew')
const Pipeline = () => import('@/views/Pipeline/PipelineNew')
const Property = () => import('@/views/Property/Property')
const PropertyMergeView = () => import('@/bundles/Property/views/merge/PropertyMergeView.vue');
const Company = () => import('@/views/Company/CompanyNew')
const AccessDenied = () => import('@/components/AccessDenied')

// new public functions
const PublicMailchimpAvailability = () => import(/* webpackChunkName: "availability-public" */ '@/views/Public/Availability')
const PublicAvailability = () => import(/* webpackChunkName: "availability-public" */ '@/views/Public/Availability')

// Contacts
const ContactsNew = () => import(/* webpackChunkName: "contacts" */ '@/views/Contact/ContactsNew')
const Contact = () => import(/* webpackChunkName: "contact-record" */ '@/views/Contact/ContactNew')
const ContactsMergeWindow = () => import(/* webpackChunkName: "import-contacts" */'@/components/contacts/ContactsMergeWindow')

// Comparables
const ComparablesNew = () => import(/* webpackChunkName: "comparables" */ '@/views/Comparable/ComparablesNew')
const Comparable = () => import(/* webpackChunkName: "comparable" */ '@/views/Comparable/ComparableNew')
const ComparablePrint = () => import(/* webpackChunkName: "comparable-print" */ '@/views/Comparable/ComparablePrint')
const ComparableReport = () => import(/* webpackChunkName: "comparable" */ '@/views/Comparable/ComparableReport')

// Deals
const Deal = () => import('@/views/Deals/DealNew')
const DealsNew = () => import('@/views/Deals/DealsNew')
const CreateDeal = () => import('@/views/Deals/DealsCreate')
const DealPrint = () => import('@/views/Deals/DealPrint')
const DealInvoice = () => import('@/components/invoice/InvoicePreview')

// Spatial
const SpatialSearch = () => import('@/components/spatial/SpatialSearch')

// Public Email Preferences
const EmailPreferences = () => import('@/views/Preferences/EmailPreferences')

// User Settings
const UserSettingsRootViewNew = () => import('@/views/Account/RootViewNew')
const UserProfile = () => import('@/components/account/ProfileInfoForm')
const UserSecurity = () => import('@/views/Account/Security')
const UserPermissions = () => import('@/views/Account/Permissions')
const UserSettings = () => import('@/views/Account/Settings')
const UserSubscription = () => import('@/views/Account/Subscription')
const UserMain = () => import('@/bundles/Settings/views/Main.vue')
const UserApps = () => import('@/views/Account/Apps')
const MagicLinkAuth = () => import('@/bundles/Auth/views/MagicLinkAuth');
const DashboardOrder = () => import('@/bundles/Settings/components/DashboardOrder.vue');
const NotificationsSettings = () => import('@/bundles/Settings/components/NotificationsSettings.vue');
const OpexSettings = () => import('@/bundles/Settings/components/OpexSettings.vue');

const Unfollow = () => import('@/views/Unfollow')
const ThanksSignIn = () => import('@/views/ThanksSignIn')

// Members
const Members = () => import('@/views/Member/Members')

// Marketing
const MarketingDashboard = () => import(/* webpackChunkName: "marketing" */ '@/views/Marketing/MarketingDashboard')
const PressReleases = () => import(/* webpackChunkName: "marketing" */ '@/views/Marketing/PressReleases');
const PressRelease = () => import(/* webpackChunkName: "marketing" */ '@/views/Marketing/PressRelease')
const ProcessPressRelease = () => import(/* webpackChunkName: "marketing" */ '@/views/Marketing/ProcessPressRelease')

const AccountProviderList = () => import(/* webpackChunkName: "account-provider" */ '@/bundles/AccountProvider/views/AccountProviderList.vue');

// account-routes
const MyAccounts = () => import(/* webpackChunkName: "account-routes" */ '@/bundles/Account/views/MyAccounts.vue');

const routes = [
  {
    path: '/pub/availability/:hash',
    component: PublicAvailability,
    meta: {
      title: 'Availability',
    },
    props: true,
  },
  {
    path: '/pub/:provider/availability/:hash/:mcCampaign/:mcContact',
    component: PublicMailchimpAvailability,
    meta: {
      title: 'Availability',
    },
    props: true,
  },
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    meta: {
      title: 'Welcome',
      requiresTokenAbsence: true
    },
  },
  {
    path: '/signin',
    name: 'signin',
    component: Welcome,
    meta: {
      title: 'Sign In',
      requiresTokenAbsence: true
    },
  },
  {
    path: '/signup',
    component: SignUp,
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: '/register',
    component: Register,
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/goodbye',
    component: Welcome,
    meta: {
      title: 'Goodbye',
    },
  },
  {
    path: '/create-account',
    component: CreateAccount,
    meta: {
      title: 'Create Account',
    },
  },
  {
    path: '/confirm-account',
    component: ConfirmAccount,
    meta: {
      title: 'Confirm Account',
    },
  },
  {
    path: '/order-status',
    name: 'order-status',
    component: () => import('@/components/workorder/OrderStatus.vue'),
    meta: {
      title: 'Order Status',
    },
  },
  {
    path: '/preferences',
    component: EmailPreferences,
    meta: {
      title: 'Preferences',
    },
  },
  {
    component: Unfollow,
    path: '/unfollow',
    meta: {
      title: 'Unfollow',
    },
    props: true,
  },
  {
    name: 'ThanksPage',
    path: '/sign-in/confirmation',
    meta: {
      title: 'Thanks',
    },
    component: ThanksSignIn,
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    meta: {
      title: 'Forgot Password',
    },
    component: MagicLinkAuth,
  },
  {
    path: '/my-accounts',
    name: 'my-accounts',
    component: MyAccounts,
    meta: {
      title: 'My Accounts',
      requiresTokenOrTemporaryToken: true
    },
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/NotFoundView.vue'),
  },
  // all routes without accountId should be defined above that
  {
    path: '/:accountId',
    component: AccountLayout,
    meta: {
      requiresToken: true
    },
    children: [
      {
        path: '', // handling routes if they have no account id
        beforeEnter (to, from, next) {
          redirectHandler(to, next, store, true);
        }
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: 'no-access',
        name: 'no-access',
        component: () => import('@/bundles/Account/views/NoAccessView.vue'),
        meta: {
          title: 'No Access',
        },
      },
      {
        path: 'properties',
        name: 'properties',
        component: Properties,
        meta: {
          title: 'Properties',
        },
      },
      {
        path: 'property/:id',
        name: 'property',
        component: Property,
        meta: {
          title: 'Property',
        },
        props: true,
      },
      {
        path: 'property-merge/:id',
        name: 'property-merge',
        component: PropertyMergeView,
        meta: {
          title: 'Property Merge',
        },
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: ContactsNew,
        meta: {
          title: 'Contacts',
        },
      },
      {
        path: 'availabilities',
        component: Availabilities,
        name: 'availabilities',
        meta: {
          title: 'Availabilities',
        },
      },
      {
        name: 'availability',
        path: 'availability/:id',
        component: Availability,
        props: true,
        meta: {
          title: 'Availability',
        },
      },
      {
        path: 'availability/create',
        name: 'availability-create',
        component: AvailabilityForm,
        props: true,
        meta: {
          title: 'Availability Create',
        },
      },
      {
        path: 'availability/edit/:id/:type',
        component: AvailabilityEditForm,
        name: 'availability-edit',
        meta: {
          title: 'Availability Edit',
        },
        props: true,
      },
      {
        path: 'projects',
        name: 'projects',
        component: ProjectsNew,
        meta: {
          title: 'Projects',
        },
      },
      {
        path: 'project/:id',
        name: 'project',
        component: ProjectCard,
        meta: {
          title: 'Project',
        },
        props: true,
      },
      {
        path: 'comparables',
        component: ComparablesNew,
        name: 'comparables',
        meta: {
          title: 'Comparables',
        },
      },
      {
        path: '/comparables/report',
        component: ComparableReport,
        name: 'comparables-report',
        meta: {
          title: 'Comparable Report',
        },
      },
      {
        path: 'comparables/pdf-report',
        component: () => import('@/views/Reports/ReportPDF'),
        name: 'comparable-pdf-report',
        meta: {
          title: 'Comparable PDF Report',
        },
      },
      {
        path: 'occupiers',
        component: OccupiersNew,
        name: 'occupiers',
        meta: {
          title: 'Occupiers',
        },
      },
      {
        path: 'occupier/:id',
        props: true,
        name: 'occupier',
        meta: {
          title: 'Occupier',
        },
        component: Occupier,
      },
      {
        path: 'requirements',
        name: 'requirements',
        component: RequirementsNew,
        meta: {
          title: 'Requirements',
        },
      },
      {
        path: 'requirement/:id',
        props: true,
        name: 'requirement',
        component: Requirement,
        meta: {
          title: 'Requirement',
        },
      },
      {
        path: 'pipelines',
        name: 'pipelines',
        component: PipelinesNew,
        meta: {
          title: 'Pipelines',
        },
      },
      {
        path: 'pipeline/:id',
        component: Pipeline,
        name: 'pipeline',
        props: true,
        meta: {
          title: 'Pipeline',
        },
      },
      {
        path: 'deals',
        component: DealsNew,
        name: 'deals',
        meta: {
          title: 'Deals',
        },
      },
      {
        path: 'deal/:id',
        component: Deal,
        name: 'deal',
        meta: {
          title: 'Deal',
        },
      },
      {
        path: 'deal/:id/print',
        name: 'deal-print',
        component: DealPrint,
        meta: {
          title: 'Deal Print',
        },
      },
      {
        path: 'deals-create',
        component: CreateDeal,
        name: 'deal-create',
        meta: {
          title: 'Deal Create',
        },
      },
      {
        path: 'deal/:id/invoice',
        name: 'deal-invoice',
        component: DealInvoice,
        meta: {
          title: 'Deal Invoice',
        },
      },
      {
        path: 'spatial-gis',
        component: SpatialSearch,
        name: 'spatial-search',
        meta: {
          title: 'Spatial GIS',
        },
      },
      {
        path: 'contact/:id',
        name: 'contact',
        component: Contact,
        props: true,
        meta: {
          title: 'Contact',
        },
      },
      {
        path: 'contact/:id/merge',
        name: 'contact-merge',
        component: ContactsMergeWindow,
        props: true,
        meta: {
          title: 'Contacts Merge',
        },
      },
      {
        path: 'companies',
        component: CompaniesNew,
        name: 'companies',
        props: true,
        meta: {
          title: 'Companies',
        },
      },
      {
        path: 'company/:id',
        name: 'company',
        component: Company,
        props: true,
        meta: {
          title: 'Company',
        },
      },
      {
        path: 'company/:id/merge',
        name: 'company-merge',
        component: CompaniesMergeWindow,
        props: true,
        meta: {
          title: 'Companies Merge',
        },
      },
      {
        path: 'report/:id/system',
        name: 'ReportDetail',
        component: ReportDetail,
        props: true,
        meta: {
          title: 'Report Detail',
        },
      },
      ...MarketingTemplatesRoutes,
      {
        path: 'administration',
        component: Administration,
        children: [
          {
            path: '',
            name: 'administration-root-page',
            meta: {
              title: 'Administration',
            },
            component: AdministrationRootView,
          },
          {
            path: 'member/:id',
            component: () => import('@/views/Member/MemberRoot.vue'),
            children: [
              {
                path: '',
                name: 'member-account',
                meta: {
                  title: 'Member Account',
                },
                component: () => import('@/views/Member/MemberNew.vue'),
              },
              {
                path: 'notes',
                name: 'member-notes',
                meta: {
                  title: 'Member Notes',
                },
                component: () => import('@/views/Member/MemberNotes.vue'),
              },
              {
                path: 'hr',
                name: 'member-hr',
                meta: {
                  title: 'Member HR',
                },
                component: () => import('@/views/Member/MemberHR.vue'),
              },
              {
                path: 'security',
                name: 'member-security',
                component: () => import('@/views/Member/MemberSecurity.vue'),
                meta: {
                  title: 'Member Security',
                  requiresSuperAdminRole: true
                },
              },
              {
                path: 'company-details',
                name: 'member-company-details',
                meta: {
                  title: 'Member Company Details',
                },
                component: () => import('@/views/Member/MemberCompanyDetails.vue'),
              },
              {
                path: 'license-info',
                name: 'member-license-info',
                meta: {
                  title: 'Member License Info',
                  // TODO sychdo: handle adminToken guard
                  // requiresAdminToken: true
                },
                component: () => import('@/views/Member/MemberLicense.vue'),
                beforeEnter (to, from, next) {
                  if ((store.state.adminToken && (new Date().getTime() < store.state.adminTokenExpiresTime)) || to.query.showLogin) {
                    next()
                  } else {
                    next(`${to.path}?showLogin=true`)
                  }
                },
              },
              {
                path: 'compensation',
                name: 'member-compensation',
                component: () => import('@/views/Member/MemberCompensation'),
                meta: {
                  title: 'Member Compensation',
                  // TODO sychdo: handle adminToken guard
                  // requiresAdminToken: true
                },
                beforeEnter (to, from, next) {
                  if ((store.state.adminToken && (new Date().getTime() < store.state.adminTokenExpiresTime)) || to.query.showLogin) {
                    next()
                  } else {
                    next(`${to.path}?showLogin=true`)
                  }
                },
              },
              {
                path: 'documents',
                name: 'member-documents',
                meta: {
                  title: 'Member Documents',
                },
                component: () => import('@/views/Member/MemberDocuments.vue'),
              },
              {
                path: 'login',
                name: 'member-login',
                meta: {
                  title: 'Member Login',
                },
                component: () => import('@/views/Member/MemberLogin.vue'),
              },
            ]
          },
          {
            path: 'marketing-details',
            name: 'marketing-root',
            meta: {
              title: 'Marketing Details',
            },
            component: () => import('@/views/Marketing/CompanyDetails.vue'),
          },
          {
            path: 'external-provider-list',
            name: 'account-provider-list',
            meta: {
              title: 'External Providers',
            },
            component: AccountProviderList,
          },
          {
            path: 'documents',
            name: 'documents',
            meta: {
              title: 'Marketing Documents'
            },
            component: () => import('@/components/account/view/Documents.vue'),
          },
          {
            path: 'training',
            name: 'training',
            meta: {
              title: 'Training',
            },
            component: () => import('@/components/account/view/Training.vue'),
          },
          {
            path: 'images',
            name: 'images',
            meta: {
              title: 'Images',
            },
            component: () => import('@/components/account/view/Images.vue'),
          },
          {
            path: 'account-details',
            name: 'account-details',
            meta: {
              title: 'Company Details'
            },
            component: () => import('@/components/account/view/Details.vue'),
          },
          {
            path: 'settings',
            name: 'compensation-plans',
            meta: {
              title: 'Compensation Plans',
              requiresToken: true,
              requiresFinancialAdminRole: true,
            },
            component: () => import('@/components/account/view/CompensationPlans.vue'),
          },
          {
            path: 'setup',
            meta: {
              title: 'Setup',
            },
            component: () => import('@/components/account/view/Setup.vue'),
          },
          {
            path: 'spatial-settings',
            meta: {
              title: 'Spatial and Map Settings'
            },
            name: 'administration-spatial-settings',
            component: () =>
              import('@/components/account/view/SpatialSettings.vue'),
          },
          {
            path: 'data-management',
            name: 'administration-data-management',
            meta: {
              title: 'Data Management'
            },
            component: () => import('@/views/Administration/DataManagement'),
          },
          {
            path: 'data-management/:id',
            meta: {
              title: 'Data Management'
            },
            props: true,
            component: () => import('@/views/Administration/ProcessImport'),
            name: 'data-management-process',
          },
          {
            path: 'requests',
            meta: {
              title: 'Feature Requests and Bug Tracker'
            },
            name: 'administration-requests',
            component: () => import('@/views/Administration/RequestsTracker'),
          },
          {
            path: 'request/:id',
            meta: {
              title: 'Request'
            },
            name: 'administration-request-record',
            component: () => import('@/bundles/Requests/views/RequestView.vue'),
          },
          {
            path: 'export',
            meta: {
              title: 'Export',
            },
            component: () => import('@/views/Administration/DataExport'),
          },

          {
            path: 'members',
            name: 'members',
            meta: {
              title: 'Members and Teams',
              requiresToken: true,
              requiresFinancialAdminOrAdminRole: true,
            },
            component: Members
          },
          {
            path: 'account-settings',
            meta: {
              title: 'Settings'
            },
            name: 'administration-account-settings',
            component: () => import('@/views/Administration/AccountSettings'),
          },
          {
            path: 'dynamic-data',
            meta: {
              title: 'Dynamic Data'
            },
            name: 'administration-dynamic-data',
            component: () => import('@/views/Administration/DynamicData'),
          },
          // todo: Remove this after bee plugin testing
          {
            path: 'bee-plugin-testing',
            meta: {
              title: 'Bee Plugin Testing'
            },
            name: 'administration-bee-plugin',
            component: () => import('@/views/Administration/BeePlugin'),
          },
          {
            path: 'listing-verification',
            name: 'ListingVerificationSettings',
            meta: {
              title: 'Listing Verification Settings',
            },
            component: () => import('@/views/Administration/ListingVerification'),
          }
        ]
      },
      {
        path: 'saved-sets',
        name: 'sets',
        component: SavedSets,
        meta: {
          title: 'Saved Sets',
        },
      },
      {
        path: 'member/tile',
        name: 'member-tile',
        component: () => import('@/components/members/MemberTile.vue'),
        props: true,
        meta: {
          title: 'Member Tile',
        },
      },
      {
        path: 'member/profile/:id',
        name: 'member-profile',
        component: () => import('@/components/members/ProfileComplete.vue'),
        props: true,
        meta: {
          title: 'Member Profile',
        },
      },
      {
        path: 'sign-order/:id',
        name: 'sign-order',
        component: () => import('@/components/workorder/OrderForm.vue'),
        props: true,
        meta: {
          title: 'Sign Order',
        },
      },
      {
        path: 'order-approve/:id',
        name: 'order-approve',
        component: () => import('@/components/workorder/OrderApproval.vue'),
        props: true,
        meta: {
          title: 'Order Approval',
        },
      },
      {
        path: 'access',
        name: 'access-denied',
        component: AccessDenied,
        meta: {
          title: 'Access Denied',
        },
      },
      {
        path: 'availability/:id/print',
        component: () => import('@/views/Availability/AvailabilityPrint.vue'),
        name: 'availability-print',
        props: true,
        meta: {
          title: 'Availability Print',
        },
      },
      {
        path: 'comparable/:id/print',
        component: ComparablePrint,
        name: 'comparable-print',
        meta: {
          title: 'Comparable Print',
        },
      },
      {
        path: 'comparable/:id',
        name: 'comparable',
        component: Comparable,
        props: true,
        meta: {
          title: 'Comparable',
        },
      },
      {
        component: UserSettingsRootViewNew,
        path: 'settings',
        children: [
          {
            path: '',
            name: 'user-profile',
            meta: {
              title: 'Profile',
            },
            component: UserProfile,
          },
          {
            path: 'security',
            name: 'user-security',
            meta: {
              title: 'Security',
            },
            component: UserSecurity,
          },
          {
            path: 'permissions',
            name: 'user-permissions',
            meta: {
              title: 'Permissions',
            },
            component: UserPermissions,
          },
          {
            path: 'user-settings',
            name: 'user-settings',
            component: UserSettings,
            meta: {
              title: 'Settings',
            },
            props: true,
          },
          {
            path: 'main',
            name: 'user-main',
            meta: {
              title: 'Main Settings',
            },
            component: UserMain,
            children: [
              {
                path: 'dashboard',
                name: 'settings-dashboard',
                meta: {
                  title: 'Dashboard Settings',
                },
                component: DashboardOrder,
              },
              {
                path: 'notifications',
                name: 'settings-notifications',
                meta: {
                  title: 'Notifications Settings',
                },
                component: NotificationsSettings,
              },
              {
                path: 'opex',
                name: 'settings-opex',
                meta: {
                  title: 'Opex Settings',
                },
                component: OpexSettings,
              },
            ]
          },
          {
            path: 'subscription',
            name: 'user-subscription',
            meta: {
              title: 'Subscription',
            },
            component: UserSubscription,
          },
          {
            path: 'apps',
            name: 'user-apps',
            component: UserApps,
            meta: {
              title: 'Apps',
            },
            props: true,
          },
        ],
      },
      {
        path: 'marketing',
        name: 'marketing',
        component: MarketingDashboard,
        props: true,
        meta: {
          title: 'Marketing',
        },
      },
      {
        path: 'marketing/press-releases',
        name: 'PressReleases',
        component: PressReleases,
        meta: {
          title: 'Press Releases',
        },
        props: true,
      },
      {
        name: 'PressRelease',
        path: 'marketing/press-release/:id',
        component: PressRelease,
        props: true,
        meta: {
          title: 'Press Release',
        },
      },
      {
        name: 'ProcessPressRelease',
        path: 'marketing/press-release/:id/approve/:approveKey',
        component: ProcessPressRelease,
        props: true,
        meta: {
          title: 'Process Press Release',
        },
      },
      ...MarketingRoutes,
      ...ReportRoutes,
      {
        path: '*', // handling all not existing routes and routes with id in url
        beforeEnter: (to, from, next) => {
          redirectHandler(to, next, store, true);
        }
      },
    ],
  },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

let router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export const DEFAULT_TITLE = 'Slick Cactus';

router.beforeEach((to, from, next) => {
  let result = true;
  if (to.meta?.requiresTokenOrTemporaryToken) {
    result = requiresTokenOrTemporaryTokenGuard(to, from, next, store);
  }

  if (to?.meta?.requiresTokenAbsence) {
    result = requiresTokenAbsenceGuard(to, from, next, store);
  }

  if (to.matched.some(record => record.meta?.requiresToken)) {
    result = requiresTokenGuard(to, from, next, store);
  }

  if (to.meta?.requiresRedirect) {
    result = requiresRedirectGuard(to, from, next, store);
  }

  if (to.meta?.requiresAdminToken) {
    result = requiresAdminTokenGuard(to, from, next, store);
  }

  if (to.meta?.requiresFinancialAdminRole) {
    result = requiresFinancialAdminRoleGuard(to, from, next, store);
  }

  if (to.meta?.requiresSuperAdminRole) {
    result = requiresSuperAdminRoleGuard(to, from, next, store);
  }

  if (to.meta?.requiresFinancialAdminOrAdminRole) {
    result = requiresFinancialAdminOrAdminRoleGuard(to, from, next, store);
  }

  if (result) {
    store.dispatch(RootActions.CANCEL_PENDING_REQUESTS);
    next();
  }
})

router.afterEach((to, from) => {
  // this is a hack to fix the issue with the title not being updated with history mode (need to have it in future)
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title ? `${to.meta.title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  });
});

export default router
