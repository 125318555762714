import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { httpV2 } from '@/bundles/Http/factory/httpFactory';

const errorMapper = new ErrorMapper();
const resource = '/savedset';
const dynamicResource = '/filters';

export default {
  list: async function ({ token, params = [], onDownloadEventHandler = null }) {
    try {
      const paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      return await instance.get(`${resource}/list${paramsString}`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOne: async function ({ id, token, params }) {
    try {
      const paramsString = generateParams(params);

      return await instance.get(`${resource}/${id}${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getDynamicOne: async function ({ id, token }) {
    try {
      return await instance.get(`${dynamicResource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateFeatured: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/featured`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateDynamic: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${dynamicResource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  send: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/sendSet/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  // TODO: drop this one when all will use new call
  exportToExcel: async function ({ token, id, payload }) {
    try {
      if (!id) id = 0;
      return await instance.post(`${resource}/${id}/export`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  exportToExcelNew: async function (payload) {
    try {
      return await httpV2.post(`/export`, payload);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  printAgentRanking: async function (token, params) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`/deal/agent-ranking/report${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addTo: async function ({ token, url, payload }) {
    try {
      return await instance.post(`${url}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getList: async function ({ token, params = [] }) {
    try {
      return await instance.get(`${resource}/list?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getListByType: async function ({ token, resourceType, resourceId, params }) {
    const queryParams = generateParams(params);

    try {
      return await instance.get(`${resource}/record/${resourceType}/${resourceId}${queryParams}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  combineSets: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/copy`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeItem: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/removeItem`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateSort: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/updateSort`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeRecord: async function ({ token, savedSetId, resourceType, resourceId }) {
    try {
      return await instance.delete(`savedSet/${savedSetId}/${resourceType}/${resourceId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  deleteRecords: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/delete-records`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  deleteByEdge: async function ({ id, token }) {
    try {
      return await instance.delete(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  togglePublicNotifications: async function (id, token) {
    try {
      return await instance.patch(`${resource}/${id}/public-notifications`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
