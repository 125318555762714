import { ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import i18n from '@/bundles/Localization';
import { getSelectItems } from '@/bundles/Pipelines/helpers';
import { getUniqueSortedUseTypesByType } from '@/bundles/Property/helpers/getUseTypesByType';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';
import { getPropertyCenterTypeTextValues } from '@/bundles/Property/enums/PropertyCenterTypeEnum';
import { getSaleConditionsTextValues } from '@/bundles/Comparables/enums/SaleConditionsEnum';

export const getGlobalFilters = () => ({
  group: 'deals',
  model: false,
  isActive: false,
  icon: 'mdi-currency-usd',
  title: 'Closed Deals',
  children: [
    {
      type: 'title',
      title: 'Deal Details'
    },
    {
      icon: 'mdi-adjust',
      title: 'Business Line',
      name: 'deal_business_line',
      type: 'business_line_switch',
    },
    {
      type: 'custom_transaction_filter',
      children: [
        {
          title: 'Transaction Type',
          name: 'deal_transaction_type',
        },
        {
          title: 'Investment Sale',
          name: 'deal_investment'
        },
        {
          title: 'SubLease',
          name: 'deal_sublease'
        },
        {
          title: 'Consulting',
          name: 'deal_consulting'
        }
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Type',
      items: [],
      name: 'property_type',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.sale_conditions),
      items: getSaleConditionsTextValues(),
      name: 'deal_sale_conditions',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Primary Use',
      items: [],
      name: 'property_primary_use',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },

    {
      icon: 'mdi-adjust',
      title: 'Use Type(s)',
      name: 'property_use_type',
      type: 'use_type_filter',
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.retail_center_type),
      items: getPropertyCenterTypeTextValues(),
      name: 'property_center_type',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Transaction Date',
      items: ['', ''],
      name: 'deal_close_transaction_date',
      type: 'date_range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Transaction Year',
      items: [...Array(new Date().getFullYear() - 1999)].map((_, index) => new Date().getFullYear() - index),
      name: 'deal_close_year',
      type: 'one_select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Transaction Category',
      items: getSelectItems().transactionCategories,
      name: 'deal_category',
      type: 'select',
    },
    {
      type: 'custom_size_filter',
      meta: {
        prefix: 'deal'
      },
      children: [
        {
          title: 'Deal Size',
          name: 'deal_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'deal_land_size',
          type: 'range'
        },
        {
          name: 'deal_land_size_unit',
          type: 'helper'
        },
        {
          title: 'Units / Keys',
          name: 'deal_units',
          type: 'range'
        }
      ]
    },
    {
      type: 'payment_details',
      meta: {
        prefix: 'deal'
      },
      children: [
        {
          title: 'Total Consideration',
          name: 'deal_total_consideration',
          type: 'range',
        },
        {
          title: 'In-House Gross',
          name: 'deal_inhouse_gross',
          type: 'range',
        },
        {
          title: 'Commissions %',
          name: 'deal_commission_percentage',
          type: 'range',
        },
        {
          title: 'Paid Amount',
          name: 'deal_paid_amount',
          type: 'range',
        },
        {
          title: 'Due Date',
          name: 'deal_invoice_due_date',
          type: 'date_range',
        },
        {
          title: 'Paid Date',
          name: 'deal_close_paid_date',
          type: 'date_range',
        },
        {
          title: 'Due Amount',
          name: 'deal_due_amount',
          type: 'range',
          meta: {
            class: 'text-left'
          }
        },
        {
          title: 'Invoice Amount',
          name: 'deal_invoice_amount',
          type: 'range',
          meta: {
            class: 'text-left'
          }
        },
        {
          title: 'Invoice Date',
          name: 'deal_invoice_date',
          type: 'date_range',
        },
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Deal Status',
      name: 'deal_status',
      type: 'select',
      items: [
        { text: 'Pending', value: 'pending' },
        { text: 'Approved', value: 'approved' },
        { text: 'Invoiced', value: 'invoiced' },
        { text: 'Paid', value: 'paid' }
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Contract Pending Status',
      name: 'deal_contract_pending',
      type: 'one_select',
      items: [
        { text: 'All deals', value: 'all' },
        { text: 'Contract Pending', value: 'pending' },
        { text: 'No Contract Pending', value: 'notPending' }
      ]
    },
    {
      type: 'title',
      title: 'Referrals'
    },
    {
      icon: 'mdi-adjust',
      title: 'Referral Send Type',
      name: 'deal_inbound_outbound',
      type: 'inbound_outbound_switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'Referral Contact or Company Keyword',
      items: ['', ''],
      name: 'deal_referral_keyword',
      type: 'textfield',
    },
    {
      type: 'title',
      title: 'Pipeline'
    },
    {
      icon: 'mdi-adjust',
      title: 'Source Category',
      items: getSelectItems().sources,
      name: 'pipeline_source',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Pipeline Business Line',
      name: 'pipeline_business_line',
      type: 'business_line_switch',
    },
    {
      type: 'title',
      title: 'Market & Location'
    },
    {
      icon: 'mdi-adjust',
      title: 'City',
      name: 'property_location_city',
      type: 'city_combobox',
      class: 'mt-0 pa-0'
    },
    {
      icon: 'mdi-adjust',
      title: 'State',
      name: 'property_location_state',
      type: 'states_combobox',
    },
    {
      icon: 'mdi-adjust',
      title: 'Zip / Postal Code',
      name: 'property_location_postal_cd',
      type: 'postal_code_combobox',
    },
    {
      icon: null,
      title: null,
      items: [],
      name: 'property_market',
      type: 'dynamic_market',
      children: [
        {
          icon: 'mdi-adjust',
          title: `Property ${i18n.t('apn_number')}`,
          items: ['', ''],
          name: 'property_apn_number',
          type: 'textfield',
        },
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Broker',
      items: ['', ''],
      name: 'deal_broker',
      type: 'broker',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Name',
      name: 'contact_names',
      type: 'contacts_combobox',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Deal Role',
      items: [
        { text: 'Buyer', value: ContactRolesEnum.buyer },
        { text: 'Seller', value: ContactRolesEnum.seller },
        { text: 'Landlord', value: ContactRolesEnum.landlord },
        { text: 'Seller Agent', value: ContactRolesEnum.seller_agent },
        { text: 'Buyer Agent', value: ContactRolesEnum.buyer_agent },
        { text: 'Tenant', value: ContactRolesEnum.tenant },
        { text: 'Landlord', value: ContactRolesEnum.landlord },
        { text: 'Tenant Agent', value: ContactRolesEnum.tenant_agent },
        { text: 'Landlord Agent', value: ContactRolesEnum.landlord_agent },
        { text: 'Primary Client Contact', value: ContactRolesEnum.primary_client },
        { text: 'Billing Contact', value: ContactRolesEnum.billing },
        { text: 'Source Contact', value: ContactRolesEnum.source },
        { text: 'Internal Team Member', value: ContactRolesEnum.internal_team_member },
        { text: 'Consulting Agent', value: ContactRolesEnum.consulting_agent },
        { text: 'Other', value: ContactRolesEnum.other },
      ],
      name: 'contact_deal_role',
      type: 'select',
    },
    {
      type: 'title',
      title: 'Company'
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Name',
      name: 'company_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Deal Role',
      items: [
        { text: 'Buyer', value: ContactRolesEnum.buyer },
        { text: 'Seller', value: ContactRolesEnum.seller },
        { text: 'Tenant', value: ContactRolesEnum.tenant },
        { text: 'Landlord', value: ContactRolesEnum.landlord },
      ],
      name: 'company_deal_role',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'deal_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'deal_view',
      type: 'create_updated_select',
    },
    {
      title: 'Filtered Deals',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
