import { OccupierRentType } from '@/bundles/Occupier/types';
import { getDefaultPermissions } from '@/bundles/App/helpers';
import { defaultMapStyle } from '@/bundles/Map/helpers/defaultMapStyle';
import { RentScheduleTypeEnum } from '@/bundles/Comparables/types';

export const initialState = {
  idToken: null,
  adminToken: null,
  adminTokenExpiresTime: null,
  appToken: null,
  userId: null,
  contactId: null,
  user: null,
  color: null,
  company: null,
  nav: false,
  isMiniNav: true, // TODO: need to move AppBar.vue to page layout, this is temporary solution
  roles: [],
  availabilityView: 'availabilityClient',
  dealView: 'client',
  appVersion: null,
  spatial: {
    zoom: 15,
    center: {
      longitude: 0,
      latitude: 0,
    },
    layers: [],
    markets: [],
    marketLayers: [],
    mapLayers: [],
    standardLayers: [],
    industrialLayers: [],
    officeLayers: [],
    retailLayers: [],
    markerLayers: [],
    packageLayers: [],
    farmAndRanchLayers: [],
    residentialLayers: [],
    transportationLayers: [],
    propertyGroupingLayers: [],
    municipalLayers: [],
    style: null,
    styleApprove: 'satellite',
    mapCards: defaultMapStyle.value,
    prevUrl: null,
  },
  cardMap: {
    style: defaultMapStyle.value
  },
  pressRelease: {
    includeOtherAgents: true,
  },
  search: {
    occupier: {
      keyword: '3m ',
    },
    pipeline: {
      category: '',
      keyword: '',
      showClosedWonLost: false,
      showClosedConstructionPending: true,
    },
    property: {
      keyword: '',
    },
    availability: {
      keyword: '',
    },
    contact: {
      keyword: '',
    },
    company: {
      keyword: '',
    },
    requirement: {
      keyword: '',
    },
    comparable: {
      keyword: '',
    },
    deals: {
      keyword: '',
    },
  },
  userRoles: [],
  socketConnected: false,
  socketStuff: {
    pipeline: {
      updated: false,
    },
  },
  splash: true,
  userSettings: false,
  imagesExtensions: ['png', 'jpg', 'jpeg'],
  snackbar: {
    open: false,
    message: '',
  },
  scheduleTypes: [
    { text: RentScheduleTypeEnum.new, value: RentScheduleTypeEnum.new },
    { text: RentScheduleTypeEnum.renewal, value: RentScheduleTypeEnum.renewal },
    { text: RentScheduleTypeEnum.modification, value: RentScheduleTypeEnum.modification },
    { text: RentScheduleTypeEnum.expansion, value: RentScheduleTypeEnum.expansion },
    { text: RentScheduleTypeEnum.extension, value: RentScheduleTypeEnum.extension },
  ],
  commonItems: {
    leaseType: ['Gross', 'NNN'],
    agencyType: ['Exclusive', 'Non-Exclusive', 'Owner Listed', 'Pocket'],
    availabilityType: ['sale', 'lease'],
    availabilityStatus: ['Active', 'Active (Internal)', 'Pending', 'Inactive'],
    priceAs: ['Total Price', 'Per Square Foot'],
  },
  selectedItems: [],
  isMultipleAccountAuth: false,
  temporaryAuthToken: null,
  temporaryUserInfo: null,
  activeAccount: null,
  accountSettings: {
    email_sender: '',
    email_logo: '',
    email_footer: '',
    pressReleaseDefaults: {},
    emailBlastDefaults: {},
    account: 0,
    accounting_method: '',
    accounting_fiscal_year_ending: '',
    map: {},
    report_settings: {
      property_types: [],
      property_covers: []
    },
    occupier_default_rent_type: OccupierRentType.per_year,
    permissions: getDefaultPermissions(),
  },
  userIntegrations: [],
  visitorId: null,
  cancelTokens: [],
};
