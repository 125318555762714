import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';

export const getDefaultMessages = () => ({
  [LocalizationEnum.apn_number]: 'APN #',
  [LocalizationEnum.referring]: 'Referring',
  [LocalizationEnum.receiving]: 'Receiving',
  [LocalizationEnum.schedule_type]: 'Lease Type',
  [LocalizationEnum.transaction_type]: 'Transaction Type',
  [LocalizationEnum.retail_center_type]: 'Retail Center Type',
  [LocalizationEnum.quoted_as]: 'Quoted as',
  [LocalizationEnum.occupier_use_types]: 'Occupier Use Type(s)',
  [LocalizationEnum.target_completion_year]: 'Target Completion Year',
  [LocalizationEnum.target_completion_quarter]: 'Target Completion Quarter',
  [LocalizationEnum.sale_conditions]: 'Transaction Conditions',
  [LocalizationEnum.tmi]: 'NNN/TMI',
  [LocalizationEnum.deal_internal_id]: 'Internal ID',
  [LocalizationEnum.deal_pipeline_source_category]: 'Source Category',
  [LocalizationEnum.basement_floors]: 'Basement Floors',
  [LocalizationEnum.basement_size]: 'Basement Size',
  [LocalizationEnum.number_of_floors]: 'Number of Floors',
  [LocalizationEnum.comparable_primary_use]: 'Comparable Primary Use',
  [LocalizationEnum.task]: 'task | tasks',
  [LocalizationEnum.was]: 'was | were',
  [LocalizationEnum.ownership_type]: 'Ownership Type',
  [LocalizationEnum.number_of_units]: '# of Units/Keys',
  [LocalizationEnum.team]: 'team | teams',
  [LocalizationEnum.user]: 'user | users',
  [LocalizationEnum.loading_bay_length]: 'Loading Bay Length',
});

const getTorontoMessages = () => ({
  ...getDefaultMessages(),
  [LocalizationEnum.apn_number]: 'PIN'
});

export const getLocaleMessages = (accountId: number) => {
  return accountId === 2 ? getTorontoMessages() : getDefaultMessages();
};
