import { IHeader } from '@/bundles/BaseTable/interfaces';

export const getMarketLabel = (accountId) => {
  switch (accountId) {
    case 2: return 'Region';
    case 6: return 'Submarket';
    default: return 'Submarket Cluster';
  }
}

const getMarketHeaders = (accountId) => {
  const result = [
    {
      sortable: false,
      class: '',
      icon: 'string',
      multi: [
        { text: 'Prop. Type', value: 'type', sortable: true, icon: 'string' },
        { text: 'Bldg. Class', value: 'building_class', sortable: true, icon: 'string' },
      ]
    },
    {
      text: getMarketLabel(accountId),
      value: 'filterMarketOne',
      sortable: true,
      icon: 'string'
    }
  ];

  if (accountId !== 6) {
    result.push({
      text: 'Business Park',
      value: 'filterMarketTwo',
      sortable: true,
      icon: 'string'
    });
  }

  return result;
};

export const getPropertiesHeadersData = (accountId: number): IHeader[] => {
  return [
    { text: '', value: 'image', sortable: false },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        { text: 'Name', value: 'name', sortable: true, class: '', icon: 'string' },
        { text: 'Address', value: 'address.street', sortable: true, class: '', icon: 'string' },
        {
          text: 'more address',
          value: 'citystatezip',
          sortable: false,
          class: '',
          icon: 'string',
          multi: [
            {
              text: 'City',
              value: 'address.city',
              sortable: true,
              class: '',
              icon: 'string',
            },
            {
              text: 'State',
              value: 'address.state',
              sortable: true,
              class: '',
              icon: 'string'
            },
            {
              text: 'Postal',
              value: 'address.postal',
              sortable: true,
              class: '',
              icon: 'string'
            }
          ]
        },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: getMarketHeaders(accountId),
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Building Size',
          value: 'size.building.total',
          sortable: true,
          icon: 'number'
        },
        { text: 'Land Size', value: 'size.land.total', sortable: true, icon: 'number' },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      icon: 'string',
      multi: [
        {
          text: 'Construction Status',
          value: 'construction_status',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Year Built',
          value: 'year_built',
          sortable: true,
          icon: 'number'
        }
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      icon: 'string',
      multi: [
        { text: 'True Owner', value: 'owner', sortable: true, icon: 'string' },
        { text: 'Contact', value: '', sortable: false },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      icon: 'string',
      multi: [
        { text: 'Listing Company', value: 'broker', sortable: true, icon: 'string' },
        { text: 'Contact', value: '', sortable: false },
      ],
    },
    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Updated',
          value: 'updated.date',
          sortable: true,
          icon: 'date',
        },
        {
          text: 'Created',
          value: 'created.date',
          sortable: true,
          icon: 'date',
        }
      ],
    },
  ];
};

export const getPropertiesCardHeadersData = (accountId): IHeader[] => ([
  { text: '#', value: 'number', sortable: false },
  { text: '', value: 'image', sortable: false },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Name', value: 'name', sortable: true, class: '', icon: 'string' },
      { text: 'Address', value: 'address.street', sortable: true, class: '', icon: 'string' },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: getMarketHeaders(accountId),
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Building Size',
        value: 'size.building.total',
        sortable: true,
        icon: 'number'
      },
      { text: 'Land Size', value: 'size.land.total', sortable: true, icon: 'number' },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    icon: 'string',
    multi: [
      {
        text: 'Construction Status',
        value: 'construction_status',
        sortable: true,
        icon: 'string'
      },
      {
        text: 'Year Built',
        value: 'year_built',
        sortable: true,
        icon: 'number'
      }
    ],
  },
]);
