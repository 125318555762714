import { Nullable } from '@/utils/types';
import { DealTransactionTypeEnum, IDeal } from '@/bundles/Deal/types';
import { SaleConditionsEnum } from '@/bundles/Comparables/enums/SaleConditionsEnum';

export interface ICustomDealFormData {
  name: string;
  amount: Nullable<number>;
  brokers: any[];
  percentage: Nullable<number>;
  transactionType: DealTransactionTypeEnum;
  transactionDate: Nullable<string>;
  transaction: {
    total_acres: Nullable<number>;
    total_square_feet: Nullable<number>;
  },
  category: Nullable<string>; // TODO add enum for categories
  source: Nullable<string>; // TODO add enum for categories
  sale_conditions: SaleConditionsEnum[];
  marketing: { value: number };
  internal_id: Nullable<string>;
  suite: Nullable<string>;
  totalCommission: Nullable<number>;
}

// proper type will be added in the one of the next MRs
export const getCustomDealDefaultFormData = (data?: IDeal): ICustomDealFormData => ({
  name: data?.name || '',
  amount: data?.transaction?.consideration || null,
  brokers: [] as any[],
  percentage: null, // TODO: add initial value
  transactionType: data?.transaction?.transaction_type || null,
  transactionDate: data?.transaction?.transaction_date || null,
  transaction: {
    total_acres: data?.transaction?.total_acres || null as Nullable<number>,
    total_square_feet: data?.transaction?.total_square_feet || null as Nullable<number>
  },
  category: data?.category || null,
  source: data?.source || null,
  sale_conditions: data?.transaction?.sale_conditions || [],
  marketing: data?.marketing || { value: 0 },
  internal_id: data?.internal_id || null,
  suite: data?.suite || null,
  totalCommission: null
});

export const getCustomDealCommissionFormData = () => {
  return {
    commission: null,
    commission_total: null,
    commission_per: null
  }
};
